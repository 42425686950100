<template>
  <b-container id="user-form-modal">
    <div class="top-menu">
      <i class="icon-close ml-1 cursor-pointer" @click="emitEvents('closed')" />
    </div>

    <b-container class="top-container" v-if="!hasRequest">
      <span style="color: black; font-size: 22px; text-align: center">
        Parabéns, {{ user.name }}!
      </span>

      <div class="warning-padding">
        <div class="warning-box">
          <i class="icon-information-circle" />

          <div class="warning-text">
            <span>
              O responsável pelo contrato será registrado em nome de
              <b>{{ user.name }}</b
              >.
            </span>
          </div>
        </div>
      </div>

      <div
        style="display: flex; justify-content: center; align-items: center; margin-top: 20px"
        :style="opportunity.is_responsible ? 'margin-bottom: 10px' : ''"
      >
        <input type="checkbox" class="custom-checkbox" v-model="opportunity.is_responsible" />
        Não é aluno?
      </div>

      <validation-observer
        v-if="opportunity.is_responsible"
        ref="formValidation"
        style="padding: 0 3rem"
      >
        <validation-provider
          #default="{ errors }"
          immediate
          name="Nome"
          rules="required"
          vid="name"
        >
          <small class="v-err text-danger">{{ errors[0] }}</small>
          <input-field-component
            id="name"
            placeholder="Nome Completo"
            trim
            type="text"
            v-model="opportunity.student_name"
          />
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          immediate
          name="CPF"
          rules="required"
          vid="document"
        >
          <small class="v-err text-danger">{{ errors[0] }}</small>
          <input-field-component
            id="CPF"
            placeholder="CPF"
            trim
            type="text"
            v-model="opportunity.student_document"
            :mask="['###.###.###-##']"
          />
        </validation-provider>
      </validation-observer>

      <div class="enrollment-buttons">
        <b-button class="buttons" variant="light" @click="emitEvents('closed')">
          Concluir sem Matricular
        </b-button>

        <b-button class="buttons" @click="goToImNic" variant="primary"> Gerar Matrícula </b-button>
      </div>
    </b-container>

    <div style="display: flex; justify-content: center" v-if="!hasRequest">
      <img
        :src="`${require('@/assets/images/enrollment-illustration.svg')}`"
        class="enrollment-illustration"
        :class="opportunity.is_responsible ? 'low-resolution-form' : ''"
      />
    </div>

    <div v-if="showIframe" class="iframe-holder">
      <enrollment-iframe
        @enrolled="enrolled($event)"
        @closed="closeEnroll"
        :iframeLink="iframeLink"
      />
    </div>
  </b-container>
</template>

<script>
import { BButton, BContainer } from "bootstrap-vue";
import EnrollmentIframe from "./components/EnrollmentIframe.vue";
import InputField from "@/views/components/InputField.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Vue from "vue";
import VueCookie from "vue-cookie";

Vue.use(VueCookie);

export default {
  components: {
    BButton,
    BContainer,
    EnrollmentIframe,
    ValidationObserver,
    ValidationProvider,
    "input-field-component": InputField,
  },

  props: {},

  data() {
    return {
      hasRequest: true,
      iframeLink: "",
      opportunity: null,
      showIframe: false,
      studentDocument: null,
      studentName: null,
    };
  },

  computed: {
    user() {
      return this.$store.getters["app/getUserData"];
    },
  },

  mounted() {
    this.opportunity = this.$store.getters["contract/getOpportunityForContract"];

    this.hasRequest = false;
  },

  methods: {
    emitEvents(event) {
      this.$emit(event);
    },

    documentMask(document) {
      if (document) {
        document = document.replace(/\D/g, "");
        document = document.replace(/(\d{3})(\d)/, "$1.$2");
        document = document.replace(/(\d{3})(\d)/, "$1.$2");
        document = document.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
        return document;
      } else {
        return "";
      }
    },

    goToImNic() {
      const imNicToekn = localStorage.getItem("imNicToken");
      const acessCode = localStorage.getItem("acessCode");

      const params =
        "&isResponsible=" +
        this.opportunity.is_responsible +
        "&name=" +
        this.opportunity.name +
        "&email=" +
        this.opportunity.contact.email +
        "&phone=" +
        this.opportunity.contact.phone_number +
        "&postalCode=" +
        this.opportunity.contact.postal_code +
        "&taxpayerId=" +
        this.documentMask(this.opportunity.contact.document) +
        "&franchiseId=" +
        this.opportunity.workspace.remote_id +
        "&studentName=" +
        this.opportunity.student_name +
        "&studentTaxpayerId=" +
        this.documentMask(this.opportunity.student_document);

      this.iframeLink =
        "https://pagamento.institutomix.com.br?token=" +
        imNicToekn +
        "&username=" +
        acessCode +
        params;

      this.showIframe = true;
    },

    enrolled($event) {
      const enrollData = $event;

      let contract = {
        remote_id: enrollData.contract_id,
        opportunity_id: this.opportunity.id,
        contact_id: this.opportunity.contact_id,
        user_id: this.user.id,
        enrollment_course_id: enrollData.course.id,
        enrollment_course_name: enrollData.course.description,
        // start_at: enrollData.course.data.start_date,
        // end_at: enrollData.course.data.end_date,
        number_installments: parseInt(enrollData.course.payment.installments),
        installment_price: enrollData.course.payment.value,
        installment_final_price: enrollData.course.payment.value_with_discount,
        payment_due_date: enrollData.course.payment.due_date,
        enrollment_fee: enrollData.enrollment_fee,
        enrollment_due_date: enrollData.due_date,
        contact_sign_link: enrollData.sign_url,

        is_active: false,
        is_signed: false,
      };

      if (enrollData.is_courseware) {
        contract.has_courseware = true;
        contract.courseware_price = enrollData.courseware.payment.value;

        contract.courseware_number_installments = parseInt(
          enrollData.courseware.payment.installments
        );

        contract.courseware_final_price = enrollData.courseware.payment.value_with_discount;

        contract.courseware_payment_due_date = enrollData.courseware.payment.due_date;
      }

      const opportunityModified = {
        id: this.opportunity.id,
        is_responsible: this.opportunity.is_responsible,
        student_name: this.opportunity.student_name,
        student_document: this.opportunity.student_document,
        price: enrollData.installments * enrollData.value_with_discount,
      };

      this.$store.dispatch("contract/createContract", contract).then(() => {
        this.$store.dispatch("opportunityStore/save", opportunityModified);
      });
    },

    closeEnroll() {
      this.showIframe = false;
      this.emitEvents("refresh");
    },
  },
};
</script>

<style lang="scss" scoped>
#user-form-modal {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;
  width: 100%;
  background: #ffffff;

  .custom-checkbox {
    height: 18px;
    width: 18px;
    margin-right: 5px;
    border-radius: 2px;
    border: solid 1px #046af3;
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
  }

  .custom-checkbox:checked {
    background-color: blue;
    background-image: url("../../assets/images/icons/Checkmark.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  .top-menu {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
    margin-bottom: 10px;
  }

  .top-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 10px;

    .warning-padding {
      padding: 0 2rem;

      .warning-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: left;
        padding: 10px 15px;
        margin-top: 10px;
        background: #cd364a33;
        border: solid 1px #cd364a;
        border-radius: 10px;
        color: black;

        .icon-information-circle {
          font-size: 20px;
          width: 7%;
          color: #cd364a;
        }

        .warning-text {
          text-align: center;
          width: 93%;
        }
      }
    }
    .enrollment-buttons {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-top: 20px;

      .buttons {
        width: 30%;
      }
    }
  }

  .enrollment-illustration {
    width: 80%;
    margin-top: 10px;
  }

  .iframe-holder {
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 999;
    background: #000000e1;
    overflow: hidden;
  }

  @media (max-width: 1400px) {
    .low-resolution-form {
      width: 60%;
    }
  }

  @media (max-width: 800px) {
    .top-container {
      .warning-padding {
        padding: 0 1rem;
      }

      .enrollment-buttons {
        .buttons {
          width: 40%;
        }
      }
    }

    .iframe-holder {
      .iframe {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
      }
    }
  }
}
</style>
