import { render, staticRenderFns } from "./OpportunityForm.vue?vue&type=template&id=3edb04a7&scoped=true"
import script from "./OpportunityForm.vue?vue&type=script&lang=js"
export * from "./OpportunityForm.vue?vue&type=script&lang=js"
import style0 from "./OpportunityForm.vue?vue&type=style&index=0&id=3edb04a7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3edb04a7",
  null
  
)

export default component.exports