<template>
  <div class="d-flex flex-column h-100">
    <div class="d-flex p-1">
      <i class="icon-close cursor-pointer" @click="cancel" />
    </div>

    <b-container fluid class="uploading-data-animation" v-if="hasLoading || hasStageRequest">
      <div class="d-flex align-items-center justify-content-center" style="flex-direction: column">
        <lottie-animation
          class="lottie-animation"
          ref="anim"
          :animationData="require('@/assets/images/lottie/uploading-task-data.json')"
          :loop="true"
          :autoPlay="true"
          :speed="1"
        />

        <span class="loading-text"> Carregando </span>
      </div>
    </b-container>

    <div
      class="d-flex justify-content-between content-sidebar-header"
      v-if="!hasLoading && !hasStageRequest"
    >
      <h3 class="mt-1 mx-auto">Etapa do Funil</h3>
    </div>

    <div class="d-flex mt-1 px-2" v-if="!hasLoading && !hasStageRequest">
      <ul>
        <li
          v-for="column in columns"
          :key="column.id"
          class="d-flex justify-content-between align-items-center"
          :class="`step-click`"
          :style="{
            borderColor: '#' + column.color,
          }"
          @click="change(column.id)"
        >
          <span>{{ column.name }}</span>

          <feather-icon
            v-if="column.id == currentStageId"
            icon="CheckIcon"
            size="24"
            :style="{
              color: '#' + column.color,
            }"
          />
        </li>
      </ul>
    </div>

    <ClosingOpportunityModals
      @saveDeal="saveDeal($event)"
      @refresh="$emit('refresh')"
      :opportunityProp="opportunity"
    />

    <b-modal id="bv-list_users" class="modal-dialog" hide-footer hide-header>
      <div class="d-block text-center">
        <user-list @closed="hideModal" @selected="selectUser" :showManagers="false" />
      </div>
    </b-modal>

    <b-modal class="modal-dialog" id="bv-task_form" hide-footer hide-header no-close-on-backdrop>
      <div class="d-block text-center">
        <task-form
          alternativeTitle="Registre uma tarefa para mudar essa oportunidade de etapa"
          createdFrom="Opportunity"
          :opportunity="opportunity"
          :parentId="opportunity.id"
          @closed="resetModals()"
          @saved="saveTask()"
        />
      </div>
    </b-modal>

    <b-modal
      class="modal-dialog"
      hide-footer
      hide-header
      id="bv-data_correction"
      no-close-on-backdrop
    >
      <data-correction
        v-if="opportunity.id && columns"
        :opportunityId="opportunity.id"
        :isFrom="isTypeWon ? 'Won' : ''"
        @saved="saveDataCorrection($event)"
      />
    </b-modal>

    <b-modal class="modal-dialog" id="bv-tranfer-opportunity" hide-footer hide-header>
      <transfer-workspace :opportunity="opportunity" @closed="emitEvents('closed')" />
    </b-modal>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import { integer } from "vee-validate/dist/rules";
import UserList from "../UserList.vue";
import { BContainer, BForm, BFormTextarea } from "bootstrap-vue";
import ButtonComponent from "@/views/components/ButtonComponent.vue";
import vSelect from "vue-select";
import TransferWorkspace from "../TransferWorkspace.vue";
import { verificationsForStepChange } from "@/mixins";
import TaskForm from "@/views/forms/TaskForm.vue";
import DataCorrection from "../DataCorrection.vue";
import LottieAnimation from "lottie-web-vue";
import ClosingOpportunityModals from "@/views/components/ClosingOpportunityModals.vue";
import { instance } from "@/config/index.js";

export default {
  components: {
    UserList,
    BContainer,
    BForm,
    BFormTextarea,
    ButtonComponent,
    vSelect,
    TransferWorkspace,
    TaskForm,
    DataCorrection,
    LottieAnimation,
    ClosingOpportunityModals,
  },

  props: {
    opportunity: {
      type: Object,
      required: true,
      default: () => ({}),
    },

    showUserSelector: {
      type: Boolean,
      default: true,
    },

    showWorkspaceSelector: {
      type: Boolean,
      default: false,
    },

    isUpdate: {
      type: Boolean,
      default: false,
    },

    fromNewOpportunity: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      hasRequest: true,
      hasLoading: false,
      hasStageRequest: true,

      isTypeWon: false,
      currentUser: null,

      currentStageId: {
        type: integer,
        required: true,
        default: () => 0,
      },

      columns: null,
    };
  },

  mixins: [verificationsForStepChange],

  computed: {
    user() {
      return {
        id: this.opportunity.user_id || 0,
        name: this.opportunity.user_name || "Alguém",
        email: this.opportunity.user_email || "teste@teste.com",
      };
    },

    workspace() {
      return this.$store.getters["app/getUserWorkspace"];
    },
  },

  created() {
    this.fetchSalesStages();
  },

  mounted() {
    this.currentStageId = this.opportunity.sales_stage_id;
    this.currentUser = this.opportunity.user;
  },

  methods: {
    hideModal(modal) {
      this.$bvModal.hide("bv-list_users");
      this.$bvModal.hide(modal);
    },

    resetModals() {
      this.$bvModal.hide("bv-update_kanban_step");
      this.hideModal("bv-update_kanban_stage");
      this.hideModal("bv-task_form");
    },

    checkIfWon(stageId) {
      this.columns.forEach((element) => {
        if (stageId == element.id && element.type === "Won") {
          this.isTypeWon = true;
        }
      });
    },

    emitEvents(event) {
      this.$emit(event);
      this.$emit("canceled");
    },

    openModal(modal) {
      this.$bvModal.show(modal);
    },

    hideDealModal(modal) {
      this.$bvModal.hide(modal);
      this.$emit("canceled");
    },

    saveTask() {
      this.hideModal("bv-task_form");
      this.MIXIN_saveTaskForm();
    },

    saveDataCorrection(eventInfo) {
      if (eventInfo.product_id || eventInfo.price) {
        this.opportunity.product_id = eventInfo.product_id;
        this.opportunity.price = eventInfo.price;
      }

      this.MIXIN_saveDataCorrection();
    },

    saveDeal(event) {
      if (event.opportunity) {
        this.getObjectAttributes(event.opportunity, this.opportunity);
      }

      this.hideModal(`bv-close_deal_${event.state.toLowerCase()}`);

      this.MIXIN_saveDeal(event.state);
    },

    getObjectAttributes(source, modifiedObject) {
      for (let attribute in source) {
        if (source.hasOwnProperty(attribute)) {
          modifiedObject[attribute] = source[attribute];
        }
      }
    },

    selectUser(user) {
      this.opportunity.user = user;
      this.opportunity.user_id = user.id;

      this.$store.dispatch("opportunityStore/save", this.opportunity).then(() => {
        this.$emit("saved");
        this.hideModal();
      });
    },

    change(step) {
      this.currentStageId = step;
      this.checkIfWon(step);
      this.save();
    },

    cancel() {
      this.$emit("canceled");
    },

    save() {
      if (this.fromNewOpportunity) {
        this.$emit("newOpportunityStep", this.currentStageId);
      } else {
        this.opportunity.sales_stage_id = this.currentStageId;
        this.hasLoading = true;
        this.MIXIN_verificationsForStepChange();
      }
    },

    showUserModal() {
      if (this.workspace.role !== "salesRep") {
        this.$bvModal.show("bv-list_users");
      }
    },

    fetchSalesStages() {
      if (this.opportunity?.workspace_id && this.opportunity?.workspace_id != this.workspace.id) {
        this.fetchSalesStagesFromWorkspace();
      } else {
        this.fetchSalesStagesFromCurrentWorkspace();
      }
    },

    fetchSalesStagesFromCurrentWorkspace() {
      this.$store
        .dispatch("opportunityStore/fetchStages")
        .then((response) => {
          this.columns = response.data;

          if (this.fromNewOpportunity) {
            this.columns = this.columns.filter(
              (stage) => stage.type == "PreSale" || stage.type == "First"
            );
          }
        })
        .finally(() => {
          this.hasStageRequest = false;
        });
    },

    fetchSalesStagesFromWorkspace() {
      this.hasStageRequest = true;

      this.salesStageRequest()
        .then((response) => {
          this.columns = response.data;
        })
        .finally(() => {
          this.hasStageRequest = false;
        });
    },

    salesStageRequest() {
      const workspaceId = this.opportunity.workspace_id;

      const postUrl = `/workspaces/${workspaceId}/sales-stages?limit=500`;

      return new Promise((resolve, reject) => {
        instance()
          .get(postUrl)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px;
}

ul > li {
  display: flex;
  border-radius: 6px;
  border-left: 3px solid;
  border: 1px solid;
  margin-bottom: 6px;
  height: 2em;
  list-style: none;
  height: 55px;
  line-height: 55px;
  text-align: center;
  padding: 0 1em;
}

ul > li.step-1 {
  border-color: #333;
}

.step-click {
  cursor: pointer;
}

.user-selector {
  padding: 0.25em;
  border: 1px solid #046af3;
  border-radius: 6px;
}

.modal-close-deal {
  display: flex;
  h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #000000;
  }
  .buttons {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
    button {
      min-width: 100px;
      border-radius: 6px;
      padding: 13px 0;
      text-align: center;
      margin: 0 5px !important;
    }
    button:last-child {
      margin-right: 0px;
    }
  }
}

.uploading-data-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 95vh;

  .lottie-animation {
    position: relative;
    width: 225px;
    height: 225px;
  }

  .loading-text {
    font-size: 15px;
    color: #000000;
  }
}
</style>
