const error_status = {
  400: "Informações falharam na verificação",
  401: "Seu login expirou!",
  403: "Você não tem permissão!",
  404: "Recurso não encontrado",
  405: "Método não usado",
  406: "Conteúdo não encontrado",
  407: "Necessita de proxy",
  408: "Tempo de requsição esgotado",
  409: "Conflito com o servidor",
  410: "Conteúdo deletado no servidor",
  411: "'Content-Length' deve ser definido",
  412: "Pré condição falhou no servidor",
  413: "Requisição muito grande!",
  414: "URI muito grande!",
  415: "Formato de mídia não suportado",
  416: "'Range' não satisfaz o especificado",
  417: "Especificação não satisfeita",
  418: "Eu sou um bule de chá!",
  425: "Aguarde e tente novamente",
  426: "Necessita de upgrade!",
  428: "Pré condição necessária",
  429: "Muitas requsições!",
  431: "Cabeçalho muito grande!",
  451: "Recurso legalmente bloqueado!",
  500: "Erro no servidor, contate um adiministrador!",
  501: "Método da requisição não suportada pelo servidor!",
  502: "Conexão com servidor falhou!",
  503: "Serviço Indisponível",
  504: "Servidor não respondeu a tempo",
  505: "Erro de Versão HTTP",
  506: "Servidor não suporta a funcionalidade solicitada",
  507: "Armazenamento insuficiente para completar a solicitação",
  508: "Loop detectado ao processar a solicitação",
  510: "Requer autenticação de rede",
  511: "Conexão de rede foi perdida",
};

let vueInstance;

export const ToastService = {
  setVueInstance(instance) {
    vueInstance = instance;
  },

  showError(error) {
    if (vueInstance) {
      const errorData = error?.response?.data;
      const errorType = error?.response?.data?.status;

      if (!errorData || !errorType) {
        vueInstance.$bvToast.toast("Algum erro desconhecido aconteceu.", {
          title: "Erro Desconhecido",
          autoHideDelay: 3000,
          variant: "danger",
          toaster: "b-toaster-top-left",
          solid: true,
        });

        console.log("Erro desconhecido", errorData, errorType);

        return;
      }

      if (errorData.errors?.length > 1) {
        errorData.errors.forEach((element) => {
          vueInstance.$bvToast.toast(element.errors.description, {
            title: `${error_status[errorType]}`,
            autoHideDelay: 3000,
            variant: "danger",
            toaster: "b-toaster-top-left",
            solid: true,
          });
        });
      } else {
        vueInstance.$bvToast.toast(errorData.message, {
          title: `${error_status[errorType]}`,
          autoHideDelay: 3000,
          variant: "danger",
          toaster: "b-toaster-top-left",
          solid: true,
        });
      }
    }
  },
};
