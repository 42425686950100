/* eslint-disable no-empty-pattern */
import Vue from "vue";
import { instance } from "@/config/index.js";

const initialState = () => ({
  mappedTypesDictionary: [
    { type: "reset", label: "Botão de Reset" },
    { type: "text", label: "Campo de Texto" },
    { type: "email", label: "Campo de Email" },
    { type: "tel", label: "Campo de Telefone" },
    { type: "checkbox", label: "Campo de Checkbox" },
    { type: "radio", label: "Campo de Seleção Radial" },
    { type: "select", label: "Campo de Seleção Drop" },
  ],

  allMappedFields: [
    { name: "name", title: "Nome" },
    { name: "phone_number", title: "Telefone" },
    { name: "contact_name", title: "Nome do contato" },
    { name: "email", title: "Email" },
    { name: "document", title: "CPF" },
    { name: "id_number", title: "RG" },
    { name: "birthdate", title: "Data de nascimento" },
    { name: "address_line1", title: "Endereço" },
    { name: "address_line2", title: "Complemento" },
    { name: "city_name", title: "Cidade" },
    { name: "neighborhood", title: "Bairro" },
    { name: "state_code", title: "Estado" },
    { name: "postal_code", title: "CEP" },
    { name: "instagram", title: "Instagram" },
    { name: "linkedin", title: "Linkedin" },
    { name: "facebook", title: "Facebook" },
  ],
});

const getters = {
  getAllTypes(state) {
    return state.mappedTypesDictionary;
  },

  getMappedFields(state) {
    return state.allMappedFields;
  },
};

const mutations = {};

const actions = {
  fetchForms({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/forms${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  viewForm({}, id) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/forms/${id}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  createForm({}, payload) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .post(`/workspaces/${workspaceId}/forms`, payload)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  updateForm({}, payload) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .put(`/workspaces/${workspaceId}/forms/${payload.id}`, payload)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  deleteForm({}, id) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .delete(`/workspaces/${workspaceId}/forms/${id}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  actions,
};
