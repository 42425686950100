<template>
  <div v-click-outside="onClickOutside" class="querie-drop">
    <label for="inner-input" :class="{ 'float-label': !value, 'filled-label': value }">
      {{ inputLabel }}
    </label>

    <input
      @click="isVisibleDropdown = true"
      @input="updateValue($event.target.value), queueSearch()"
      id="inner-input"
      class="inner-input"
      type="text"
      autocomplete="false"
      name="hidden"
      v-model="innerValue"
    />

    <i v-if="value" @click="$emit('clear')" class="icon-close-circle-outline clear-icon" />

    <div v-show="isVisibleDropdown" class="dropdown">
      <div v-if="hasRequest" class="spinner-holder">
        <b-spinner />
      </div>

      <div
        @click="handleOptionClick(option)"
        v-if="!hasRequest"
        v-for="(option, index) in dropOptions"
        :key="index"
        class="option-select"
      >
        <avatar :avatarLetter="$service.getFirstLetter(option[selectLabel])" :size="3" />

        <div class="option-text">
          <div class="overflow-text" style="color: #000000">
            {{ option[selectLabel] }}
          </div>

          <div v-if="secondSelectLabel" class="overflow-text">
            {{ formatPhoneNumber(option[secondSelectLabel]) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/views/components/Avatar.vue";
import { BSpinner } from "bootstrap-vue";
import vClickOutside from "v-click-outside";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },

  components: {
    Avatar,
    BSpinner,
  },

  props: {
    value: {
      type: String,
      default: "",
    },

    inputLabel: {
      type: String,
      default: "",
    },

    selectLabel: {
      type: String,
      default: "name",
    },

    secondSelectLabel: {
      type: String,
      default: null,
    },

    asyncFunction: {
      type: Function,
      default: () => {
        return null;
      },
    },
  },

  data() {
    return {
      dropOptions: [],

      innerValue: this.value,

      queueingSearch: null,

      hasRequest: false,
      isVisibleDropdown: false,
    };
  },

  watch: {
    value() {
      this.innerValue = this.value;
    },
  },

  mounted() {
    this.callAsyncFunction();
  },

  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },

    onClickOutside() {
      this.isVisibleDropdown = false;
    },

    queueSearch() {
      window.clearTimeout(this.queueingSearch);

      this.queueingSearch = setTimeout(() => {
        this.callAsyncFunction(this.value);
      }, 850);
    },

    handleOptionClick(option) {
      this.$emit("optionPicked", option);

      this.isVisibleDropdown = false;
    },

    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber) return;

      let regex = /^(\d{2})?(\d{2})(\d)?(\d{4})(\d{4})$/;
      let match = phoneNumber.match(regex);

      if (match) {
        let country_code = match[1] ? match[1] : "";
        let area_code = match[2];
        let ninth_digit = match[3] ? match[3] : "";
        let first_part = match[4];
        let second_part = match[5];

        return (
          (country_code ? "+" + country_code + " " : "") +
          "(" +
          area_code +
          ") " +
          (ninth_digit ? ninth_digit + " " : "") +
          first_part +
          "-" +
          second_part
        );
      } else {
        return phoneNumber;
      }
    },

    async callAsyncFunction(...args) {
      if (this.asyncFunction) {
        this.hasRequest = true;

        try {
          const result = await this.asyncFunction(...args);

          this.dropOptions = result;
        } finally {
          this.hasRequest = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.querie-drop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  border: 1px solid #d2d2d2;

  .float-label {
    transition: all 0.3s;
    font-size: 14px;
    position: absolute;
    top: 12px;
    left: 10px;
    padding: 0 10px;
    color: #4c5862;
    background-color: #ffffff;
    transition: all 0.1s;
    will-change: transform;
  }

  .inner-input {
    padding: 5px 20px;
    height: 45px;
    width: 100%;
    border: none;
    outline: none;
    color: #4c5862;
  }

  .clear-icon {
    font-size: 16px;
    line-height: 0px;
    position: absolute;
    top: 52%;
    right: 10px;
    color: #4c5862;
    transform: translateY(-50%);
    will-change: transform;
    cursor: pointer;
  }

  .dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    max-height: 400px;
    top: 100%;
    left: 0;
    z-index: 999;
    width: 100%;
    padding: 5px;
    border-radius: 6px;
    background: #ffffff;
    box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
    overflow-x: hidden;
    overflow-y: auto;

    .option-select {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 5px 10px;
      cursor: pointer;

      .option-text {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 100%;
        gap: 3px;

        .overflow-text {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }
      }
    }

    .option-select:hover {
      background: #046af3;
      color: #ffffff;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: #f5f6f8;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 1px solid transparent;
      background-clip: content-box;
      background-color: #b0bfd4;
    }

    .spinner-holder {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.querie-drop:focus-within {
  .float-label {
    top: -14px;
    left: 10px;
    padding: 3px 10px;
    color: #d2d2d2;
    background-color: #ffffff;
    color: #999999;
    transform: translate(0%, 0%);
  }
}

.filled-label {
  font-size: 14px;
  position: absolute;
  top: -14px;
  left: 10px;
  padding: 3px 10px;
  color: #d2d2d2;
  background-color: #ffffff;
  color: #999999;
  transform: translate(0%, 0%);
  transition: all 0.3s;
  will-change: transform;
}
</style>
