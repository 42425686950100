<template>
  <div
    @dragover.prevent
    @dragleave.prevent="handleDragLeave($event)"
    @drop="dropHandler($event)"
    :class="hasDraggable ? 'draggable-box white-box' : 'white-box'"
  >
    <floating-quick-messages
      v-if="floatingQuickMessagesState"
      @quickMessageSelected="getQuickMessageAndCloseOptions($event)"
      @close="changeQuickMessagesBoxState(false)"
      @quickMessagesLoaded="setQuickMessages($event)"
      :bufferedMessages="quickMessagesBuffer"
      :opportunity="opportunity"
      :message="newMessage"
    />

    <floating-open-new-room
      v-if="floatingOpenNewRoomState"
      @selectBot="selectBot($event)"
      @selectTemplate="selectTemplate($event)"
      @close="changeFloatingOpenNewRoomState(false)"
      :opportunity="opportunity"
    />

    <div v-if="!checkPermissionInRoom()" class="input-box" style="word-wrap: break-word">
      Aviso: A sala atual pertence ao atendente {{ newestChat.user_name }}
    </div>

    <div v-else-if="isOpenRoom" v-show="!hasDraggable" class="input-box">
      <v-emoji-picker
        v-if="showEmojiSelection"
        @select="selectEmoji($event)"
        id="emojiSelector"
        class="emoji-selector"
      />

      <feather-icon
        v-if="!recording && !mobilePausedRecording"
        @click="displayEmojiSelector()"
        id="emojiSelector"
        icon="SmileIcon"
        size="20"
        style="margin-right: 5px"
        color="#14223B"
      />

      <textarea
        v-if="!recording && !mobilePausedRecording"
        @keydown="checkUserInput($event)"
        @input="checkFormMobileInput($event), adjustTextareaHeight()"
        @keyup.enter="checkMobileEnter($event)"
        @paste="checkFilePaste"
        :style="{ height: textareaHeight }"
        v-model="newMessage"
        id="omnichannelNewMessage"
        type="text"
        placeholder="Mensagem..."
        autocomplete="off"
        ref="messageInput"
      />

      <i
        v-if="!recording && !mobilePausedRecording && !isSendingFile"
        @click="getDocument()"
        class="icon-clip mr-1"
      />

      <div v-if="!recording && !mobilePausedRecording && isSendingFile" class="mr-1">
        <b-spinner style="color: #046af3" />
      </div>

      <input
        @change="sendMessage()"
        type="file"
        id="documentInput"
        style="display: none"
        multiple
      />

      <div v-if="recording || mobilePausedRecording" class="audio-box">
        <feather-icon
          @click="clearAudio()"
          icon="Trash2Icon"
          size="22"
          style="cursor: pointer"
          color="#FF3E33"
        />

        <div class="sending-instruction">Clique para enviar</div>

        <div class="timer-recorder">
          <div class="green-ring" />

          <div>
            {{ formatTime(timer) }}
          </div>
        </div>
      </div>

      <div
        @click="getButtonActionByClick()"
        @touchstart="handleTouchStart()"
        @touchend="handleTouchEnd()"
        @touchcancel="handleTouchEnd()"
        id="inputButton"
        class="rounded-enter"
      >
        <i
          v-if="(newMessage && newMessage.length) || recording || mobilePausedRecording"
          class="icon-paper-plane"
          style="font-size: 19px; margin-left: 2px; margin-top: 1px; color: white"
        />

        <feather-icon v-else color="#ffffff" id="recordAudio" size="21" icon="MicIcon" />
      </div>
    </div>

    <div
      v-else-if="!isOpenRoom && newestChat && !openRoomFallback && !floatingOpenNewRoomState"
      class="input-box"
    >
      <div class="responsible-avatar">
        <avatar
          :avatarLetter="$service.getFirstLetter(opportunity.name)"
          :hasBorder="true"
          :hasStatus="false"
          :size="2"
          avatarColor="#046AF3"
        />

        <div class="overflow-text">
          {{ opportunity.name }}
        </div>
      </div>

      <b-button
        v-if="!hasTemplateSendRequest"
        @click="reopenImbotRoom()"
        class="reopen-button"
        variant="primary"
      >
        <span v-if="!hasRoomOpenRequest">Reabrir Sala</span>

        <b-spinner v-else variant="light" small />
      </b-button>

      <b-button
        @click="changeFloatingOpenNewRoomState(true)"
        class="reopen-button"
        variant="primary"
      >
        <div v-if="!hasTemplateSendRequest">Mudar Canal</div>

        <b-spinner v-else variant="light" small />
      </b-button>
    </div>

    <div v-else class="input-box">
      <input
        v-model="newMessage"
        id="openRoomNewMessage"
        type="text"
        placeholder="Mensagem..."
        autocomplete="off"
        style="width: calc(100% - 110px)"
      />

      <b-button
        @click="changeFloatingOpenNewRoomState(true)"
        class="reopen-button"
        variant="primary"
      >
        <div>Abrir Sala</div>
      </b-button>
    </div>

    <b-modal v-if="templateData" id="bv-template_form" class="modal-dialog" hide-footer hide-header>
      <temp-form
        @send-template="sendTemplate"
        @closed="$bvModal.hide('bv-template_form')"
        :templateData="templateData"
        :opportunity-data="opportunity"
      />
    </b-modal>
  </div>
</template>

<script>
import Avatar from "@/views/components/Avatar.vue";
import { BButton, BSpinner } from "bootstrap-vue";
import encoderPath from "opus-recorder/dist/encoderWorker.min.js";
import FloatingOpenNewRoom from "./FloatingOpenNewRoom.vue";
import FloatingQuickMessages from "./FloatingQuickMessages.vue";
import moment from "moment";
import Recorder from "opus-recorder";
import TemplateForm from "@/views/forms/TemplateForm.vue";
import { VEmojiPicker } from "v-emoji-picker";

export default {
  components: {
    Avatar,
    BButton,
    BSpinner,
    FloatingOpenNewRoom,
    FloatingQuickMessages,
    "temp-form": TemplateForm,
    VEmojiPicker,
  },

  props: {
    opportunity: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },

    newestChat: {
      required: true,
      default: function () {
        return {};
      },
    },
  },

  data() {
    return {
      floatingQuickMessagesState: false,
      floatingOpenNewRoomState: false,

      quickMessagesBuffer: null,

      templateData: null,

      lastKey: null,
      newMessage: "",
      textareaHeight: "24px",
      maxTextareaHeight: 100,
      filesArray: [],

      audioBlob: null,
      audioChunks: [],
      mediaRecorder: null,
      recording: false,

      timer: 0,
      timerLoop: null,

      mobilePausedRecording: false,

      startTime: null,
      endTime: null,
      touchTime: null,

      isSendingMessage: false,
      isSendingFile: false,

      showEmojiSelection: false,

      hasRoomOpenRequest: false,
      hasTemplateSendRequest: false,

      openRoomFallback: false,

      handleTouchStart: () => {},
    };
  },

  computed: {
    user() {
      return this.$store.getters["app/getUserData"];
    },

    role() {
      return this.$store.getters["app/getUserRole"];
    },

    isOpenRoom() {
      if (!this.newestChat) {
        return false;
      }

      return this.newestChat.closed_at === undefined || this.newestChat.closed_at === null;
    },

    quickMessages() {
      return this.$store.getters["omnichannel/getQuickMessages"];
    },

    hasDraggable() {
      return this.$store.getters["omnichannel/getIsHovering"];
    },
  },

  mounted() {
    this.$store.dispatch("omnichannel/changeHoveringState", false);

    this.preventMobileContextMenuOnElement();

    this.createRecorder();

    this.handleTouchStart = this.debounce(this.handleTouchStartOriginal, 200);
  },

  watch: {
    isOpenRoom() {
      if (this.isOpenRoom) {
        this.hasRoomOpenRequest = false;
      }
    },
  },

  methods: {
    getDocument() {
      document.getElementById("documentInput").click();
    },

    changeQuickMessagesBoxState(state) {
      this.floatingQuickMessagesState = state;
    },

    changeFloatingOpenNewRoomState(state) {
      this.floatingOpenNewRoomState = state;
    },

    handleDragLeave(event) {
      this.$store.dispatch("omnichannel/changeHoveringState", false);
    },

    isMobile() {
      return window.innerWidth <= 800;
    },

    startTimer() {
      if (!this.timerLoop) {
        this.timer = 0;

        this.timerLoop = setInterval(this.updateTimer, 1000);
      }
    },

    stopTimer() {
      clearInterval(this.timerLoop);
      this.timerLoop = null;
    },

    updateTimer() {
      this.timer++;
    },

    preventMobileContextMenuOnElement() {
      const inputButton = document.getElementById("inputButton");

      if (inputButton) {
        inputButton.addEventListener("contextmenu", (event) => {
          event.preventDefault();
        });
      }
    },

    setQuickMessages(messages) {
      this.quickMessagesBuffer = messages;
    },

    checkPermissionInRoom() {
      if (!this.newestChat) {
        return true;
      }

      if (this.newestChat.closed_at != null && this.newestChat.closed_at != undefined) {
        return true;
      }

      if (this.newestChat.user_id != this.user.id && this.role == "salesRep") {
        return false;
      }

      return true;
    },

    hasParentWithId(element, parentId) {
      let currentElement = element;

      while (currentElement && currentElement !== document.body) {
        if (currentElement.id === parentId) {
          return true;
        }

        currentElement = currentElement.parentNode;
      }

      return false;
    },

    checkFilePaste(event) {
      if (event.clipboardData && event.clipboardData.items) {
        for (let i = 0; i < event.clipboardData.items.length; i++) {
          if (event.clipboardData.items[i].kind === "file") {
            const fileInput = document.getElementById("documentInput");
            const dropedFile = new DataTransfer();

            const file = event.clipboardData.items[i].getAsFile();
            const uniqueTimestamp = Date.now() + i;

            dropedFile.items.add(
              new File([file], file.name + uniqueTimestamp, {
                type: file.type,
              })
            );

            fileInput.files = dropedFile.files;
            this.sendMessage();
          }
        }
      }
    },

    displayEmojiSelector() {
      this.showEmojiSelection = !this.showEmojiSelection;

      if (this.showEmojiSelection) {
        const clickHandler = (e) => {
          if (!this.hasParentWithId(e.target, "emojiSelector")) {
            this.showEmojiSelection = false;

            document.removeEventListener("click", clickHandler);
          }
        };

        document.addEventListener("click", clickHandler);
      }
    },

    selectEmoji(emoji) {
      this.newMessage += emoji.data;
    },

    async checkMicrophonePermission() {
      const permissionStatus = await navigator.permissions.query({ name: "microphone" });

      return permissionStatus.state === "granted";
    },

    async requestMicrophonePermission() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

        stream.getTracks().forEach((track) => track.stop());
      } catch (error) {
        this.$bvToast.toast(
          "Algumas funções do sistema precisam de acesso ao microfone para funcionar!",
          {
            title: `Acesso ao microfone negado!`,
            autoHideDelay: 3000,
            variant: "danger",
            toaster: "b-toaster-top-left",
            solid: true,
          }
        );
      }
    },

    showToastRequestingMicrophonePermission() {
      this.$bvToast.toast("Por favor, permita o acesso ao microfone para gravar áudio!", {
        title: `Permissão negada!`,
        autoHideDelay: 3000,
        variant: "warning",
        toaster: "b-toaster-top-left",
        solid: true,
      });

      this.requestMicrophonePermission();
    },

    async getButtonActionByClick() {
      if (this.isMobile()) {
        return;
      }

      if ((this.newMessage && this.newMessage.length) || this.mobilePausedRecording) {
        if (this.$refs.messageInput) {
          this.$refs.messageInput.focus();
        }

        this.sendMessage();

        return;
      }

      if (await this.checkMicrophonePermission()) {
        this.recordAudio();
      } else {
        this.showToastRequestingMicrophonePermission();
      }
    },

    debounce(func, wait) {
      let timeout;

      return function (...args) {
        const context = this;

        clearTimeout(timeout);

        timeout = setTimeout(() => func.apply(context, args), wait);
      };
    },

    handleTouchStartOriginal() {
      if ((this.newMessage && this.newMessage.length) || this.mobilePausedRecording) {
        if (this.$refs.messageInput) {
          this.$refs.messageInput.focus();
        }

        this.sendMessage();

        return;
      }

      if (!this.recording) this.recordAudioViaTouch();
    },

    handleTouchEnd() {
      if (this.newMessage && this.newMessage.length && !this.recording) {
        return;
      }

      this.recordAudioViaTouch();
    },

    async recordAudioViaTouch() {
      if (await this.checkMicrophonePermission()) {
        if (this.isMobile() && !this.mobilePausedRecording && !this.newMessage?.length) {
          this.recordAudio();

          return;
        }
      } else {
        this.showToastRequestingMicrophonePermission();
      }
    },

    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);

      const remainingSeconds = seconds % 60;

      return `
      ${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}
      `;
    },

    adjustHeightAfterLineBreak() {
      const textarea = document.getElementById("omnichannelNewMessage");

      let height = textarea.scrollHeight;

      if (height > this.maxTextareaHeight) {
        height = this.maxTextareaHeight;
      }

      this.textareaHeight = height + 12 + "px";
    },

    adjustTextareaHeight() {
      const textarea = document.getElementById("omnichannelNewMessage");

      textarea.style.height = "24px";

      const newHeight = Math.min(textarea.scrollHeight, this.maxTextareaHeight);

      textarea.style.height = newHeight + "px";

      this.textareaHeight = newHeight + "px";
    },

    clearInputField() {
      this.filesArray = [];

      const documentInput = document.getElementById("documentInput");

      documentInput.value = "";

      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
      }

      if (documentInput.value) {
        documentInput.type = "text";
        documentInput.type = "file";
      }
    },

    checkForQuickMessages() {
      const matchMask = this.newMessage.match(/\/\w+/g);

      if (matchMask) {
        const command = matchMask[0];

        this.quickMessages.forEach((element) => {
          if (element.id == command) {
            this.newMessage = element.message;

            return;
          }
        });
      }

      this.changeQuickMessagesBoxState(false);
    },

    getQuickMessageAndCloseOptions(event) {
      const words = this.newMessage.split(" ");

      if (words.length > 1) {
        words[words.length - 1] = event.message;

        this.newMessage = words.join(" ");
      } else {
        this.newMessage = event.message;
      }

      this.changeQuickMessagesBoxState(false);

      const inputField = document.getElementById("omnichannelNewMessage");

      if (!inputField) return;

      inputField.focus();

      this.newMessage += " ";

      this.$nextTick(() => {
        const inputEvent = new Event("input", { bubbles: true });

        inputField.dispatchEvent(inputEvent);
      });
    },

    clearAudio() {
      this.timer = 0;
      this.mobilePausedRecording = false;
      this.recording = false;

      this.mobilePausedRecording = false;
      this.recording = false;

      this.clearInputField();

      this.stopTimer();

      if (!this.isMobile()) {
        this.audioBlob = null;

        this.audioChunks = [];
      }
    },

    checkFormMobileInput(event) {
      if (window.innerWidth > 800) {
        return;
      }

      if (
        (event.inputType == "deleteContentBackward" && this.lastKey == "/") ||
        event.data == " "
      ) {
        this.checkForQuickMessages();
      }

      if (this.newMessage) {
        this.lastKey = this.newMessage.charAt(this.newMessage.length - 1);
      }

      if (event.data === "/") {
        this.changeQuickMessagesBoxState(true);
      }
    },

    checkMobileEnter(event) {
      if (window.innerWidth > 800) {
        return;
      }

      if (
        this.newMessage &&
        this.newMessage.length &&
        (event.key == "Enter" || event.keyCode === 13 || event.which === 13)
      ) {
        this.sendMessage();
      }
    },

    checkUserInput(event) {
      if (window.innerWidth < 800) {
        return;
      }

      if (event.key === "Enter" || event.keyCode === 13 || event.which === 13) {
        if (event.shiftKey) {
          const inputElement = event.target;
          const cursorPosition = inputElement.selectionStart;
          const inputValue = inputElement.value;

          this.newMessage =
            inputValue.substring(0, cursorPosition) + "\n" + inputValue.substring(cursorPosition);

          this.adjustHeightAfterLineBreak();

          inputElement.selectionStart = cursorPosition + 1;
          inputElement.selectionEnd = cursorPosition + 1;

          event.preventDefault();
        } else {
          event.preventDefault();

          if (this.newMessage && this.newMessage.length) {
            this.sendMessage();
          }
        }
      }

      if (event.key === "Backspace" || event.code === "Backspace" || event.keyCode === 8) {
        const inputElement = event.target;
        const inputValue = inputElement.value;
        const cursorPosition = inputElement.selectionStart;

        if (
          cursorPosition > 0 &&
          inputValue.charAt(cursorPosition - 1) === "/" &&
          inputValue.charAt(cursorPosition) === ""
        ) {
          this.changeQuickMessagesBoxState(false);
        }
      }

      if (event.key === "/" || event.code === "Slash" || event.keyCode === 191) {
        this.changeQuickMessagesBoxState(true);
      }

      if (event.key === " " || event.code === "Space" || event.keyCode === 32) {
        this.checkForQuickMessages();
      }
    },

    dropHandler(event) {
      event.preventDefault();

      const dataTransfer = event.dataTransfer;
      const files = dataTransfer.files;

      if (files.length > 0) {
        const fileInput = document.getElementById("documentInput");
        const dropedFile = new DataTransfer();

        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const uniqueTimestamp = Date.now() + i;

          dropedFile.items.add(
            new File([file], file.name + uniqueTimestamp, {
              type: file.type,
            })
          );
        }

        fileInput.files = dropedFile.files;
        this.sendMessage();
      }

      this.$store.dispatch("omnichannel/changeHoveringState", false);
    },

    createRecorder() {
      const configs = {
        encoderPath,
        numberOfChannels: 1,
        encoderSampleRate: 48000,
        resampleQuality: 7,
      };

      this.mediaRecorder = new Recorder(configs);

      this.settingMediaRecorderEvents();
    },

    settingMediaRecorderEvents() {
      this.mediaRecorder.onstart = () => {
        this.mediaRecorderOnStart();
      };

      this.mediaRecorder.ondataavailable = (data) => {
        this.mediaRecorderOnDataAvailable(data);
      };

      this.mediaRecorder.onstop = (data) => {
        this.mediaRecorderOnStop(data);
      };
    },

    mediaRecorderOnStart() {
      this.audioBlob = null;
      this.audioChunks = [];
    },

    mediaRecorderOnDataAvailable(data) {
      this.audioChunks.push(data);
    },

    showErrorToast() {
      this.$bvToast.toast(
        "Ocorreu um erro ao reabrir a sala! Verifique se já não existe outra mais recente aberta.",
        {
          title: `Sala não reaberta!`,
          autoHideDelay: 3000,
          variant: "danger",
          toaster: "b-toaster-top-left",
          solid: true,
        }
      );
    },

    handleEmptyMessageOnNewRoom() {
      const newRoomMessageInput = document.getElementById("openRoomNewMessage");

      newRoomMessageInput.focus();

      this.$bvToast.toast("Digite uma mensagem para abrir a sala!", {
        title: `Mensagem vazia!`,
        autoHideDelay: 3000,
        variant: "warning",
        toaster: "b-toaster-top-left",
        solid: true,
      });
    },

    reopenImbotRoom() {
      if (this.hasRoomOpenRequest) {
        return;
      }

      this.hasRoomOpenRequest = true;

      const chatData = {
        dialog_id: this.newestChat.id,
        opportunity_id: this.opportunity.id,
      };

      this.$store
        .dispatch("omnichannel/reopenChat", chatData)
        .then((response) => {
          if (response) {
            this.$emit("roomReopened", response.data);
          }
        })
        .catch((error) => {
          if (error.response?.message?.includes("WhatsApp Business")) {
            this.openRoomFallback = true;
          }

          this.hasRoomOpenRequest = false;
        });
    },

    mediaRecorderOnStop() {
      const elapsedTime = this.endTime - this.startTime;

      this.touchTime = elapsedTime / 1000;

      this.audioBlob = new Blob(this.audioChunks, {
        type: "audio/ogg",
      });

      const fileInput = document.getElementById("documentInput");
      const audioFileList = new DataTransfer();
      const uniqueTimestamp = Date.now();

      audioFileList.items.add(
        new File([this.audioBlob], "audio_" + uniqueTimestamp, {
          type: "audio/ogg",
        })
      );

      fileInput.files = audioFileList.files;

      if (this.touchTime < 1) {
        this.clearAudio();
      } else if (this.isMobile()) {
        this.mobilePausedRecording = true;
      } else {
        this.newMessage = null;

        this.sendMessage();
      }
    },

    recordAudio() {
      try {
        if (!this.recording) {
          this.recording = true;
          this.startTime = new Date().getTime();
          this.mobilePausedRecording = false;

          this.mediaRecorder.start();
          this.startTimer();
        } else {
          this.endTime = new Date().getTime();

          if (this.mediaRecorder) {
            this.mediaRecorder.stop();
          }

          this.stopTimer();
          this.recording = false;
        }
      } catch (error) {
        console.error("Error starting/stopping the recorder:", error);
      }
    },

    selectTemplate(template) {
      this.templateData = template;

      this.$nextTick(() => {
        this.$bvModal.show("bv-template_form");
      });
    },

    getTemplateComponents(components) {
      if (!components?.length) return [];

      const componentsWithParams = [];

      components.forEach((element) => {
        if (element?.parameters?.length > 0) {
          componentsWithParams.push(element);
        }
      });

      return componentsWithParams;
    },

    selectBot(bot) {
      if (!this.newMessage || this.newMessage.length === 0) {
        this.handleEmptyMessageOnNewRoom();

        return;
      }

      this.changeFloatingOpenNewRoomState(false);

      const openRoomData = {
        bot_id: bot.id,
        bot_name: bot.name,
        message: this.newMessage,
        opportunity_id: this.opportunity.id,
        keep_bot_on_dialog: bot.keep_bot_on_dialog,
      };

      this.$store.dispatch("omnichannel/openChat", openRoomData).then((response) => {
        this.newMessage = null;
        this.$emit("roomOpened", response.data);
      });
    },

    sendTemplate(template) {
      this.changeFloatingOpenNewRoomState(false);
      this.hasTemplateSendRequest = true;

      const template_header = template.components.find((component) => component.type === "HEADER");
      const template_footer = template.components.find((component) => component.type === "FOOTER");
      const template_body = template.components.find((component) => component.type === "BODY");

      if (template_header) {
        template_header.type = "Header";

        delete template_header.example;
      }

      if (template_body) {
        template_body.type = "Body";

        delete template_body.example;
      }

      if (template_footer) {
        template_footer.type = "Footer";

        delete template_footer.example;
      }

      const header = template_header?.text || null;
      const footer = template_footer?.text || null;
      const body = template_body?.text || " ";

      const components = this.getTemplateComponents(template.components);

      const template_item = {
        name: template.name,
        language: template.language,
        components: components,
      };

      const templateOpenRoom = {
        bot_id: template.bot?.id,
        bot_name: template.bot?.name,
        opportunity_id: this.opportunity.id,
        keep_bot_on_dialog: template.bot?.keep_bot_on_dialog,
        media_type: "WhatsappWaba",

        header: header,
        footer: footer,
        message: body,

        template: template_item,
      };

      this.$store
        .dispatch("omnichannel/openChat", templateOpenRoom)
        .then((response) => {
          this.newMessage = null;

          this.$emit("roomOpened", response.data);
        })
        .finally(() => {
          this.hasTemplateSendRequest = false;
        });
    },

    sendMessage() {
      const newMessage = this.newMessage;
      const now = moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      const fileInput = document.getElementById("documentInput");

      this.timer = 0;
      this.newMessage = null;
      this.textareaHeight = "24px";
      this.mobilePausedRecording = false;

      const bodyObject = {
        dialog_id: this.newestChat.id,

        message_object: {
          message: newMessage ? newMessage : null,
          timestamp: now,
        },
      };

      if (fileInput.files.length > 0) {
        this.sendMessageWithFile(fileInput, bodyObject);

        return;
      }

      this.$store.dispatch("omnichannel/sendMessage", bodyObject).finally(() => {
        this.isSendingMessage = false;
      });
    },

    async sendMessageWithFile(fileInput, message) {
      this.isSendingFile = true;

      const files = fileInput.files;
      const filePromises = [];

      //for (let i = 0; i < files.length; i++) {
      for (let i = 0; i < 1; i++) {
        const file = files[i];

        const promise = new Promise((resolve) => {
          const reader = new FileReader();
          const mimeType = file.type;
          let base64String;

          reader.onload = (event) => {
            base64String = event.target.result.split(",")[1];

            const fileData = {
              name: file.name,
              base64File: "data:" + mimeType + ";base64," + base64String,
            };

            this.filesArray.unshift(fileData);
            resolve();
          };

          reader.readAsDataURL(file);
        });

        filePromises.push(promise);
      }

      await Promise.all(filePromises);

      message.dialogId = this.opportunity.remote_id;
      message.message_object.message = null;

      message.message_object.file = this.filesArray[0];

      this.$store.dispatch("omnichannel/sendMessage", message).finally(() => {
        this.isSendingMessage = false;
        this.isSendingFile = false;

        this.clearInputField();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.white-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  padding-bottom: 10px;
  left: 0;
  bottom: 0px;
  width: 100%;
  background: #ffffff;

  .input-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: calc(100% - 10px);
    padding: 7px 10px 7px 10px;
    border: solid 2px #cbced1;
    border-radius: 800px;
    background: #ffffff;

    .reopen-button {
      font-size: 12px;
      padding: 10px 20px;
      border-radius: 20px;
    }

    .responsible-avatar {
      display: flex;
      align-items: center;
      gap: 5px;
      max-width: 50%;
      overflow: hidden;

      .overflow-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }

    .emoji-selector {
      position: absolute;
      left: 0;
      bottom: 50px;
    }

    .audio-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;

      .sending-instruction {
        font-size: 13px;
        padding: 5px 12px;
        border-radius: 20px;
        background: #f2f3f5;
        color: #8e8e8e;
      }

      .timer-recorder {
        display: flex;
        align-items: center;
        width: 70px;
        gap: 7px;
        color: #000000;

        .green-ring {
          height: 20px;
          width: 20px;
          border-radius: 100%;
          border: 4px solid #2ed47a;
        }
      }
    }

    .rounded-enter {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px;
      border-radius: 100%;
      background: #046af3;
      cursor: pointer;
    }

    i {
      font-size: 20px;
      cursor: pointer;
    }

    textarea {
      width: 90%;
      border: none;
      resize: none;

      &::-webkit-scrollbar {
        width: 0px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 13px;
        border: none;
        background-clip: content-box;
      }
    }

    textarea:focus,
    input:focus {
      outline: none;
    }

    input {
      width: 90%;
      border: none;
    }
  }

  @media (max-width: 800px) {
    position: fixed;
    padding-left: 5px;
    width: calc(100% - 15px);

    .input-box {
      .audio-box {
        width: 85%;
      }
    }
  }
}

.draggable-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  bottom: 0px;
  z-index: 100000;
  padding: 1rem;
  opacity: 0;
}
</style>
