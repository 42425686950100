import axios from "axios";
import store from "@/store";
import { ToastService } from "@/services/toastService";

export const instance = () => {
  const createdInstance = axios.create({
    baseURL: process.env.VUE_APP_URL_API_CRM,
    timeout: 30000,
    headers: {
      "Content-Type": "application/json",
      "X-Access-Token": localStorage.getItem("token"),
    },
  });

  createdInstance.interceptors.response.use(
    (response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return Promise.reject({
          data: response.data.message,
          status: response.status,
        });
      }
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        store.dispatch("app/logout");
        return Promise.resolve({ data: {} });
      } else {
        ToastService.showError(error);

        return Promise.reject(error);
      }
    }
  );

  return createdInstance;
};

export default instance;
