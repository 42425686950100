/* eslint-disable no-empty-pattern */
import Vue from "vue";
import { instance } from "@/config/index.js";

const initialState = () => ({});

const getters = {};

const mutations = {};

const actions = {
  forecast({}, data) {
    return new Promise((resolve, reject) => {
      instance()
        .post("automations/forecast", data)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchAutomations({}, params) {
    return new Promise((resolve, reject) => {
      instance()
        .get(`automations${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  viewAutomation({}, id) {
    return new Promise((resolve, reject) => {
      instance()
        .get(`automations/${id}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  insertAutomation({}, data) {
    return new Promise((resolve, reject) => {
      instance()
        .post("automations", data)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  patchAutomation({}, data) {
    return new Promise((resolve, reject) => {
      instance()
        .patch(`automations/${data.id}`, data)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  deleteAutomation({}, id) {
    return new Promise((resolve, reject) => {
      instance()
        .delete(`automations/${id}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getAutomationVersions({}, data) {
    return new Promise((resolve, reject) => {
      instance()
        .get(`automations/${data.id}/versions${data.params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getAutomationVersion({}, data) {
    return new Promise((resolve, reject) => {
      instance()
        .get(`automations/${data.id}/versions/${data.version_id}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  publishAutomationVersion({}, data) {
    return new Promise((resolve, reject) => {
      instance()
        .post(`automations/${data.id}/versions/${data.version_id}`, data)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  /////////////////////////////////////////////////////

  forecastWS({}, data) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .post(`/workspaces/${workspaceId}/automations/forecast`, data)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchAutomationsWS({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/automations${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  viewAutomationWS({}, id) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/automations/${id}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  insertAutomationWS({}, data) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .post(`/workspaces/${workspaceId}/automations`, data)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  patchAutomationWS({}, data) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .patch(`/workspaces/${workspaceId}/automations/${data.id}`, data)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  deleteAutomationWS({}, id) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .delete(`/workspaces/${workspaceId}/automations/${id}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getAutomationVersionsWS({}, data) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/automations/${data.id}/versions${data.params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getAutomationVersionWS({}, data) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const url = `/workspaces/${workspaceId}/automations/${data.id}/versions/${data.version_id}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(url)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  publishAutomationVersionWS({}, data) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .post(`/workspaces/${workspaceId}/automations/${data.id}/versions/${data.version_id}`, data)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  actions,
};
