const initialState = () => ({
  keep_filters: false,

  filters: {
    start_timestamp: null,
    end_timestamp: null,
    date_type: "updated_at",
  },

  date_types: [
    { id: "created_at", title: "Data de criação" },
    { id: "updated_at", title: "Data de atualização" },
  ],
});

const getters = {
  getFilters(state) {
    return state.filters;
  },

  getDateType(state) {
    return state.date_types;
  },

  getKeepFilters(state) {
    return state.keep_filters;
  },

  haveAnyActiveFilters(state) {
    return !!(state.filters.start_timestamp || state.filters.end_timestamp);
  },
};

const actions = {
  resetState({ state }) {
    const initial = initialState();

    if (!state.keep_filters) {
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }
  },

  cleanValues({ state }) {
    if (!state.keep_filters) {
      state.filters = {
        start_timestamp: null,
        end_timestamp: null,
        date_type: "updated_at",
      };

      return state.filters;
    }
  },

  setFilters({ state }, filters) {
    state.filters = filters;
  },

  setKeepFilters({ state }, keep_filters) {
    state.keep_filters = keep_filters;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
};
