<template>
  <div class="modal-body">
    <div class="header">
      <i @click="close()" class="icon-close" />

      <div class="title">Enviar Template</div>
    </div>

    <div class="template-overflow-div">
      <temp-preview
        @videoLoaded="handleVideoLoaded()"
        :templateDataProp="templateForPreview"
        :isVideoLoading="isVideoLoading"
        :key="previewKey"
      />

      <validation-observer ref="formValidation" class="form-body">
        <div class="section-title">Variáveis:</div>

        <div v-if="header && headerVariables?.length" class="component-section">
          <div class="section-title">Header</div>

          <div v-for="(variable, index) in headerVariables" :key="index">
            <validation-provider
              v-if="formatString(variable.type) === 'Text'"
              #default="{ errors }"
              :name="`Header {{${index + 1}}}`"
              :vid="`header-${index}`"
              rules="required"
            >
              <small class="v-err text-danger">{{ errors[0] }}</small>

              <input-field
                @handleButton="handleButton(variable)"
                :placeholder="`{{${index + 1}}}`"
                :buttonData="inputButtonData"
                :tooltipId="`header-button-${index}`"
                trim
                type="text"
                v-model="variable.text"
              />
            </validation-provider>

            <validation-provider
              v-if="formatString(variable.type) !== 'Text'"
              v-slot="{ validate, errors }"
              rules="required"
              :name="`Header {{${index + 1}}}`"
              :vid="`header-file-${index}`"
            >
              <small class="v-err text-danger">{{ errors[0] }}</small>

              <input
                @input="handleImport($event, variable)"
                @change="validate"
                :id="`header-file-${index}`"
                class="csv-import-button"
                type="file"
                ref="fileInput"
              />
            </validation-provider>

            <label
              v-if="formatString(variable.type) !== 'Text'"
              :for="`header-file-${index}`"
              class="custom-file-button"
            >
              <div v-if="!hasFileLoading" class="d-flex items-center" style="gap: 10px">
                <i :class="variable?.base64 ? 'icon-check' : 'icon-download'" />

                <div>
                  {{
                    variable?.base64
                      ? variable.name
                      : `Upload de variável de ${getFileTerm(formatString(variable.type))}`
                  }}
                </div>
              </div>

              <b-spinner v-else />
            </label>
          </div>
        </div>

        <div v-if="body && bodyVariables?.length" class="component-section">
          <div class="section-title">Body</div>

          <validation-provider
            v-for="(variable, index) in bodyVariables"
            #default="{ errors }"
            :key="index"
            :name="`Body {{${index + 1}}}`"
            :vid="`body-${index}`"
            rules="required"
          >
            <small class="v-err text-danger">{{ errors[0] }}</small>

            <input-field
              @handleButton="handleButton(variable)"
              :placeholder="`{{${index + 1}}}`"
              :buttonData="inputButtonData"
              :tooltipId="`body-button-${index}`"
              trim
              type="text"
              v-model="variable.text"
            />
          </validation-provider>
        </div>

        <div v-if="footer && footerVariables?.length" class="component-section">
          <div class="section-title">Footer</div>

          <validation-provider
            v-for="(variable, index) in footerVariables"
            #default="{ errors }"
            :key="index"
            :name="`Footer {{${index + 1}}}`"
            :vid="`footer-${index}`"
            rules="required"
          >
            <small class="v-err text-danger">{{ errors[0] }}</small>

            <input-field
              @handleButton="handleButton(variable)"
              :key="index"
              :placeholder="`{{${index + 1}}}`"
              :buttonData="inputButtonData"
              :tooltipId="`footer-button-${index}`"
              trim
              type="text"
              v-model="variable.text"
            />
          </validation-provider>
        </div>

        <div v-if="buttons && buttonsVariables?.length && hasCopyCode" class="component-section">
          <div class="section-title">Botões</div>

          <div v-for="(variable, index) in buttonsVariables" :key="index">
            <validation-provider
              v-for="(param, i) in variable.parameters"
              #default="{ errors }"
              :key="i"
              :name="`Botões {{${i + 1}}}`"
              :vid="`buttons-${i}`"
              rules="required"
            >
              <small class="v-err text-danger">{{ errors[0] }}</small>

              <input-field
                @handleButton="handleButton(variable)"
                :key="i"
                :placeholder="`{{${i + 1}}}`"
                mask="XXXXXXXXXXXXXXX"
                trim
                type="text"
                v-model="param.coupon_code"
              />
            </validation-provider>
          </div>
        </div>
      </validation-observer>
    </div>

    <div class="footer-send">
      <div class="bot-holder">
        <div class="bot-icon">
          <i class="icon-imbot" />
        </div>

        <div>{{ templateData?.bot?.name }}</div>
      </div>

      <div @click="sendTemplate()" class="send-button">
        <feather-icon size="20" icon="SendIcon" />

        <div>{{ fromBatchMessaging ? "Confirmar" : "Enviar" }}</div>
      </div>
    </div>

    <b-modal
      id="bvModal-edit-variable-quick-opportunity-info"
      class="modal-dialog"
      hide-footer
      hide-header
    >
      <div class="dictionary-modal">
        <div class="header">
          <i
            class="icon-close"
            @click="$bvModal.hide('bvModal-edit-variable-quick-opportunity-info')"
          />

          <span class="title">Selecione a variável desejada</span>
        </div>

        <div class="variables-holder">
          <div
            v-for="(variable, index) in possibleVariables"
            @click="selectOpportunityInfo(variable)"
            :key="index"
            class="variable-box"
          >
            <div class="code">{{ variable.code }}</div>

            <div class="description">{{ variable.description }}</div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import InputField from "@/views/components/InputField.vue";
import TemplatePreview from "@/views/components/chat/TemplatePreview.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  props: {
    templateData: {
      type: Object,
      default: {},
      required: true,
    },

    opportunityData: {
      type: Object,
      default: null,
      required: false,
    },

    fromBatchMessaging: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    BSpinner,
    InputField,
    ValidationObserver,
    ValidationProvider,
    "temp-preview": TemplatePreview,
  },

  data() {
    return {
      header: null,
      headerVariables: [],

      body: null,
      bodyVariables: [],

      footer: null,
      footerVariables: [],

      buttons: null,
      hasCopyCode: false,
      buttonsVariables: [],

      previewKey: 0,

      hasFileLoading: false,
      isVideoLoading: false,

      regex: /\{\{\d+\}\}/g,

      variableToEdit: null,

      inputButtonData: {
        icon: "UsersIcon",
        tooltip: "Usar dados da oportunidade",
      },

      possibleVariables: [
        { code: "{atendente}", description: "Nome do usuário logado" },
        { code: "{campanha}", description: "Nome da campanha registrada na oportunidade" },
        { code: "{cliente}", description: "Nome do contato registrado na oportunidade" },
        { code: "{cidade}", description: "Nome da cidade registrada na oportunidade" },
        { code: "{produto}", description: "Nome do produto registrado na oportunidade" },
        { code: "{valor_produto}", description: "Valor do produto registrado na oportunidade" },
      ],
    };
  },

  created() {
    this.getTemplateComponents();
  },

  computed: {
    user() {
      return this.$store.getters["app/getUserData"];
    },

    templateForPreview() {
      return this.mountTemplate();
    },

    variablesDictionary() {
      return {
        atendente: this.user?.name,
        campanha: this.opportunityData?.campaign?.name,
        cliente: this.opportunityData?.contact?.name,
        cidade: this.opportunityData?.contact?.city_name,
        produto: this.opportunityData?.product?.name,
        valor_produto: this.opportunityData?.product?.price,
      };
    },

    massMessagingVariablesDictionary() {
      return {
        atendente: "{{user}}",
        campanha: "{{campaign}}",
        cliente: "{{name}}",
        cidade: "{{city}}",
        produto: "{{product}}",
        valor_produto: "{{price}}",
      };
    },
  },

  methods: {
    close() {
      this.$emit("closed");
    },

    handleVideoLoaded() {
      this.isVideoLoading = false;
    },

    handleButton(variable) {
      this.variableToEdit = variable;

      this.$bvModal.show("bvModal-edit-variable-quick-opportunity-info");
    },

    selectOpportunityInfo(variable) {
      const cleanCode = variable.code.replace("{", "").replace("}", "");

      if (this.fromBatchMessaging && cleanCode) {
        const varCode = this.massMessagingVariablesDictionary[cleanCode];

        this.variableToEdit.text = varCode;

        this.$bvModal.hide("bvModal-edit-variable-quick-opportunity-info");

        return;
      }

      if (this.variableToEdit && cleanCode) {
        const opportunityInfo = this.variablesDictionary[cleanCode];

        this.variableToEdit.text = opportunityInfo;

        if (!opportunityInfo) {
          this.$bvToast.toast("Oportunidade não possui a informação selecionada.", {
            title: `Informação Faltante!`,
            autoHideDelay: 1500,
            variant: "warning",
            toaster: "b-toaster-top-left",
            solid: true,
          });
        }
      }

      this.$bvModal.hide("bvModal-edit-variable-quick-opportunity-info");
    },

    getFileTerm(unformattedTerm) {
      const possibleTerms = {
        Image: "imagem",
        Video: "vídeo",
        Document: "documento",
      };

      return possibleTerms[unformattedTerm] || "arquivo";
    },

    formatString(input) {
      return input
        .toLowerCase()
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join("");
    },

    formatTextTemplates(array, component) {
      const text = component.text;

      const variables = text.match(this.regex);

      if (variables) {
        variables.forEach(() => {
          array.push({
            type: "Text",
            text: null,
          });
        });
      }
    },

    getTemplateComponents() {
      const template = { ...this.templateData };

      this.header = template.components.find((component) => component.type === "HEADER");

      if (this.header) {
        if (this.header.format != "TEXT") {
          this.headerVariables.push({
            type: this.formatString(this.header.format),
          });
        } else {
          this.formatTextTemplates(this.headerVariables, this.header);
        }
      }

      this.body = template.components.find((component) => component.type === "BODY");

      if (this.body) {
        this.formatTextTemplates(this.bodyVariables, this.body);
      }

      this.footer = template.components.find((component) => component.type === "FOOTER");

      if (this.footer) {
        this.formatTextTemplates(this.footerVariables, this.footer);
      }

      this.buttons = template.components.find((component) => component.type === "BUTTONS");

      if (this.buttons) {
        this.hasCopyCode = false;

        this.buttons?.buttons.forEach((element, index) => {
          if (element.type == "COPY_CODE") {
            this.hasCopyCode = true;

            this.buttonsVariables.push({
              type: "Button",
              sub_type: this.formatString(element.type),
              index: index,
              text: element.text,
              example: element.example,
              parameters: [
                {
                  type: "CouponCode",
                  coupon_code: null,
                },
              ],
            });
          } else {
            this.buttonsVariables.push({
              type: "Button",
              sub_type: this.formatString(element.type),
              index: index,
              text: element.text,
              example: element.example,
            });
          }
        });
      }
    },

    handleImport(event, variable) {
      this.hasFileLoading = true;

      const file = event.target.files[0];

      const reader = new FileReader();
      const mimeType = file.type;

      reader.onload = (event) => {
        const base64 = event.target.result.split(",")[1];
        const name = file.name;

        this.$set(variable, "base64", "data:" + mimeType + ";base64," + base64);
        this.$set(variable, "name", name);

        this.previewKey++;
        this.hasFileLoading = false;
        this.isVideoLoading = true;
      };

      reader.readAsDataURL(file);
    },

    mountTemplate() {
      const template = { ...this.templateData };

      let header = template.components.find((component) => component.type === "HEADER");
      let body = template.components.find((component) => component.type === "BODY");
      let footer = template.components.find((component) => component.type === "FOOTER");

      const finishedComponents = [];

      if (header) {
        if (this.headerVariables?.length) header.parameters = this.headerVariables;

        header = finishedComponents.push(header);
      }

      if (body) {
        if (this.bodyVariables?.length) body.parameters = this.bodyVariables;

        body = finishedComponents.push(body);
      }

      if (footer) {
        if (this.footerVariables?.length) footer.parameters = this.footerVariables;

        footer = finishedComponents.push(footer);
      }

      if (this.buttonsVariables?.length) {
        this.buttonsVariables.forEach((element) => {
          finishedComponents.push(element);
        });
      }

      const finishedTemplate = {
        ...template,
        components: finishedComponents,
      };

      return finishedTemplate;
    },

    removeEmptyButtons(template) {
      if (!template?.components) return;

      template.components = template.components.filter((component) => {
        if (component.type === "Button") {
          return component.example;
        }

        return true;
      });
    },

    sendTemplate() {
      const formRefs = this.$refs.formValidation;

      formRefs.validate().then((success) => {
        if (!success) {
          this.$bvToast.toast("Preencha todos os campos obrigatórios.", {
            title: `Campos Obrigatórios!`,
            autoHideDelay: 1500,
            variant: "warning",
            toaster: "b-toaster-top-left",
            solid: true,
          });

          return;
        }

        const finishedTemplate = this.mountTemplate();

        this.removeEmptyButtons(finishedTemplate);

        this.$emit("send-template", finishedTemplate);

        this.close();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background: #f6f6f6;

  .header {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 45px;
    padding-left: 10px;
    color: black;
    border-bottom: solid 1px #e5e5e5;

    .icon-close {
      position: absolute;
      cursor: pointer;
    }

    .title {
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      width: 100%;
      line-height: 30px;
      color: #000000;
    }
  }

  .template-overflow-div {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 10px;
    overflow-y: auto;
    overflow-x: hidden;

    .form-body {
      display: flex;
      flex-direction: column;
      padding: 5px 20px;
      gap: 20px;
      color: #31333f;

      .section-title {
        font-size: 18px;
        font-weight: 500;
        color: #000000;
      }

      .component-section {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .custom-file-button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        height: 44px;
        width: 100%;
        padding: 7px 5px;
        gap: 10px;
        margin: 0;
        border: 1px solid #d2d2d2;
        color: #4c5862;
        cursor: pointer;

        i {
          font-size: 20px;
          color: #046af3;
        }
      }

      input[type="file"] {
        display: none;
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 13px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 13px;
      border: 1px solid transparent;
      background-clip: content-box;
      box-shadow: inset 0 0 0 5px #00d7ab;
    }
  }

  .footer-send {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    margin-top: auto;
    border-top: solid 1px #e5e5e5;

    .bot-holder {
      display: flex;
      align-items: center;
      gap: 10px;

      .bot-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        border-radius: 50%;
        background: #00d7ab;

        i {
          font-size: 20px;
          color: #ffffff;
        }
      }
    }

    .send-button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      height: 35px;
      min-width: 100px;
      max-width: 135px;
      padding: 0px 10px;
      gap: 5px;
      border-radius: 5px;
      background: #00d7ab;
      color: #ffffff;
      cursor: pointer;
    }

    .send-button:hover {
      background: #00c1a0;
    }
  }

  .dictionary-modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .header {
      display: flex;
      align-items: center;
      text-align: center;
      position: relative;
      height: 45px;
      padding-left: 10px;
      color: black;
      border-bottom: solid 1px #e5e5e5;

      .icon-close {
        position: absolute;
        cursor: pointer;
      }

      .title {
        font-size: 20px;
        align-items: center;
        width: 100%;
      }
    }

    .variables-holder {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 10px;

      .variable-box {
        display: flex;
        flex-direction: column;
        padding: 12px 10px;
        gap: 5px;
        border: solid 1px #e5e5e5;
        border-top: none;
        cursor: pointer;

        .code {
          font-size: 16px;
          font-weight: 500;
          color: #046af2;
        }

        .description {
          font-size: 12px;
          color: #6e6b7b;
        }
      }

      .variable-box:hover {
        background: #f6f6f6;
      }

      .variable-box:first-child {
        border-top: solid 1px #e5e5e5;
      }
    }
  }
}
</style>
