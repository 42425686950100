const initialState = () => ({
  keep_filters: false,

  filters: {
    start_timestamp: null,
    end_timestamp: null,
    user: [],
    user_id: [],
    product_id: [],
    product: [],
    stage: [],
    city_name: [],
    tags: [],
    forms: [],

    source_type: null,
    campaign_id: null,
    media_id: null,
    closedType: null,
    sellerApprouch: null,
    dateType: "updated_at",
    probability: null,
    state_code: null,

    has_call: null,
    has_call_min_duration: null,
    max_call_attempts: null,
    call_start_timestamp: null,
    call_end_timestamp: null,

    has_user: null,
    has_city_name: null,
    has_state_code: null,
    has_campaign: null,
    has_product: null,

    has_generator: null,

    opportunity_type_id: null,
  },

  sortType: [],
  sortDirection: [],

  redirectFromUsers: null,

  date_types: [
    { id: "created_at", title: "Data de criação" },
    { id: "updated_at", title: "Data de atualização" },
    { id: "closed_at", title: "Data de conclusão" },
    { id: "voip", title: "Data do Voip" },
  ],

  media_types: [
    { id: "Offline", title: "Offline" },
    { id: "Google", title: "Google" },
    { id: "SocialMedia", title: "Social Media" },
  ],

  call_options: [
    { id: false, title: "Sem ligação realizada" },
    { id: true, title: "Com ligação realizada" },
  ],

  answering_options: [
    { id: true, title: "Todas as ligações atendidas" },
    {
      id: false,
      title: "Não atendidas (Recusado/caixa postal/menos 15s)",
    },
  ],

  getDurationOptions: [
    { id: "DurationLessThanAMinute", title: "Menos de um minuto" },
    { id: "DurationOneToThreeMinutes", title: "Entre 1 e 3 minutos" },
    { id: "DurationMoreThanThreeMinutes", title: "Mais de 3 minutos" },
  ],
});

const getters = {
  getFilters(state) {
    return state.filters;
  },

  getDateType(state) {
    return state.date_types;
  },

  getSortType(state) {
    return state.sortType;
  },

  getSortDirection(state) {
    return state.sortDirection;
  },

  getMediaTypes(state) {
    return state.media_types;
  },

  getCallOptions(state) {
    return state.call_options;
  },

  getAnsweringOptions(state) {
    return state.answering_options;
  },

  getDurationOptions(state) {
    return state.getDurationOptions;
  },

  getKeepFilters(state) {
    return state.keep_filters;
  },

  haveAnyActiveFilters(state) {
    function isBoolean(value) {
      return typeof value === "boolean";
    }

    if (state.filters.start_timestamp) {
      return true;
    }

    if (state.filters.end_timestamp) {
      return true;
    }

    if (state.filters.user_id.length) {
      return true;
    }

    if (state.filters.product_id.length) {
      return true;
    }

    if (state.filters.source_type) {
      return true;
    }

    if (state.filters.campaign_id) {
      return true;
    }

    if (state.filters.media_id) {
      return true;
    }

    if (state.filters.product.lenght) {
      return true;
    }

    if (state.filters.closedType) {
      return true;
    }

    if (state.filters.sellerApprouch) {
      return true;
    }

    if (state.filters.has_call != null || state.filters.has_call != undefined) {
      return true;
    }

    if (
      state.filters.has_call_min_duration != null ||
      state.filters.has_call_min_duration != undefined
    ) {
      return true;
    }

    if (state.filters.max_call_attempts) {
      return true;
    }

    if (state.filters.call_start_timestamp) {
      return true;
    }

    if (state.filters.call_end_timestamp) {
      return true;
    }

    if (typeof state.filters.probability === "number") {
      return true;
    }

    if (state.filters.stage.length) {
      return true;
    }

    if (state.filters.has_generator) {
      return true;
    }

    if (state.filters.state_code) {
      return true;
    }

    if (state.filters.city_name.length) {
      return true;
    }

    if (isBoolean(state.filters.has_user)) {
      return true;
    }

    if (isBoolean(state.filters.has_city_name)) {
      return true;
    }

    if (isBoolean(state.filters.has_state_code)) {
      return true;
    }

    if (isBoolean(state.filters.has_campaign)) {
      return true;
    }

    if (isBoolean(state.filters.has_product)) {
      return true;
    }

    if (state.filters.opportunity_type_id) {
      return true;
    }

    if (state.filters.tags.length) {
      return true;
    }

    return !!state.filters.forms.length;
  },
};

const actions = {
  resetState({ state }) {
    const initial = initialState();

    const userFromUsers = state.redirectFromUsers;

    if (state.redirectFromUsers) {
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });

      state.filters.user_id[0] = userFromUsers.id;
      state.filters.user[0] = userFromUsers;
    } else if (!state.keep_filters) {
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }

    state.redirectFromUsers = null;
  },

  cleanValues({ state }) {
    if (!state.keep_filters) {
      state.filters = {
        start_timestamp: null,
        end_timestamp: null,
        user: [],
        user_id: [],
        product_id: [],
        stage: [],
        source_type: null,
        campaign_id: null,
        media_id: null,
        product: [],
        closedType: null,
        sellerApprouch: null,
        dateType: "created_at",
        probability: null,
        has_call: null,
        has_call_min_duration: null,
        max_call_attempts: null,
        call_start_timestamp: null,
        call_end_timestamp: null,
        has_generator: null,
        state_code: null,
        city_name: [],
        has_user: null,
        has_city_name: null,
        has_state_code: null,
        has_campaign: null,
        has_product: null,
        tags: [],
        opportunity_type_id: null,
        forms: [],
      };

      state.sortType = [];

      state.sortDirection = [];

      return state.filters;
    }
  },

  setSortType({ state }, sortType) {
    state.sortType = sortType;
  },

  setSortDirection({ state }, sortDirection) {
    state.sortDirection = sortDirection;
  },

  setFilters({ state }, filters) {
    state.filters = filters;
  },

  setIdFromUsers({ state }, userId) {
    state.redirectFromUsers = userId;
  },

  setKeepFilters({ state }, keepFilters) {
    state.keep_filters = keepFilters;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
};
