<template>
  <div class="avatar-container" :style="{ height: `${size}rem`, width: `${size}rem` }">
    <div class="avatar-content" :class="{ 'has-border': hasBorder }">
      <div
        class="avatar"
        :style="{
          background: localAvatarBackground,
          color: avatarColor,
          border: `1px solid localAvatarBackground`,
        }"
      >
        <feather-icon v-if="icon" :icon="icon" :size="iconSize" />

        <div v-else>
          {{ avatarLetter }}
        </div>
      </div>
    </div>

    <div v-if="isChatBella" class="status">
      <img :src="`${require('@/assets/images/icons/chat-bella.svg')}`" alt="chat-bella" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Avatar",

  props: {
    avatarLetter: {
      type: String,
      default: "S",
    },

    hasBorder: {
      type: Boolean,
      default: true,
    },

    size: {
      type: Number,
      default: 3,
    },

    isChatBella: {
      type: Boolean,
      default: false,
    },

    avatarBackground: {
      type: String,
      default: "#D4E2F3",
    },

    avatarColor: {
      type: String,
      default: "#046AF3",
    },

    icon: {
      type: String,
      default: null,
    },

    iconSize: {
      type: String,
      default: "20",
    },

    randomColor: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      localAvatarBackground: null,
    };
  },

  created() {
    this.localAvatarBackground = this.avatarBackground;
    if (this.randomColor) {
      this.localAvatarBackground = this.$service.colorHashFromString(this.randomColor);
    }
  },
};
</script>

<style lang="scss" scoped>
.avatar-container {
  margin-right: 0.5rem;
  font-weight: 400;

  .avatar-content {
    padding: 2px;
    border-radius: 100%;
    height: 100%;
    width: inherit;

    &.has-border {
      border: 1px solid #046af3;
    }

    .avatar {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      text-transform: capitalize;
    }
  }

  .status {
    height: 10px;
    width: 10px;
    transform: translate(300%, -180%);
  }
}
</style>
